import { Model } from "@vuex-orm/core";
export default class NeighboringUse extends Model {
  static entity = "neighboringUse";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      usageTypeList: this.attr([]),
      isSensitiveUse: this.attr(false),
      targetsList: this.attr([]),
      description: this.attr(""),
      siteDistance: this.attr(0),
      position: this.attr(""),
      supposedHydraulicPositioning: this.attr(""),
      remarks: this.attr(""),
      site: this.attr(""),
    };
  }
}
