import Vue from "vue";
import VueI18n from "vue-i18n";
import { utils } from "@socotec.io/socio-vue-components";
import { purgeMessages } from "@/locales/intervention/purgeMessages";
import { sampleMessages } from "@/locales/intervention/sampleMessages";
import { materialMessages } from "@/locales/material/materialMessages";
import { surveyMessages } from "@/locales/survey/surveyMessages";
import { inverstigatedEnvsMessages } from "@/locales/intervention/inverstigatedEnvsMessages";
import { lithologyMessages } from "@/locales/intervention/lithologyMessages";
import { environmentMessages } from "@/locales/investigation";
import { localesMessages } from "@/locales/messages";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
    ...purgeMessages.en,
    ...sampleMessages.en,
    ...materialMessages.en,
    ...surveyMessages.en,
    ...lithologyMessages.en,
    ...environmentMessages.en,
    ...inverstigatedEnvsMessages.en,
    ...localesMessages.en,
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
    ...purgeMessages.fr,
    ...sampleMessages.fr,
    ...materialMessages.fr,
    ...surveyMessages.fr,
    ...lithologyMessages.fr,
    ...environmentMessages.fr,
    ...inverstigatedEnvsMessages.fr,
    ...localesMessages.fr,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  silentFallbackWarn: true,
});
