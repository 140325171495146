import { Model } from "@vuex-orm/core";

export default class Testimony extends Model {
    static entity = "testimony";
    static primaryKey = "uuid";

    static fields() {
        return {
            uuid: this.attr(),
            project: this.attr(""),
            meetingDate: this.attr(""),
            firstName: this.attr(""),
            lastName: this.attr(""),
            role: this.attr(""),
            company: this.attr(""),
            email: this.attr(""),
            telephone: this.attr(""),
            siteGuide: this.boolean(false),
            transcript: this.attr(""),
        };
    }
}