import { Project } from "@socotec.io/socio-vue-components";
import Parcel from "./Parcel";
export default class Mission extends Project {
  static entity = "mission";

  static baseEntity = super.entity;

  static fields() {
    return {
      ...super.fields(),
      globalMission: this.attr(""),
      elementaryMissions: this.attr([]),
      isImported: this.attr(false),
      dictDate: this.attr(""),
      reportingDate: this.attr(""),
      commandeDate: this.attr(""),
      visitDate: this.attr(""),
      secondVisitDate: this.attr(""),
      dashboardUuid: this.attr(""),
      siteArea: this.attr(0),
      parcelCount: this.attr(0),
      parcel: this.hasMany(Parcel, "project"),
      aosSite: this.attr(""),
    };
  }
}
