import HistoricalItem from "./HistoricalItem";

export default class Activity extends HistoricalItem {
    static entity = "activity";
    static primaryKey = "uuid";

    static fields() {
        return {
            startDate: this.attr(),
            endDate: this.attr(),
            description: this.attr(),
            canPollute: this.attr(),
            observations: this.attr(),
        };
    }
}